.game-board {
  display: grid;
  grid-template-columns: repeat(3, 100px);
  grid-template-rows: repeat(3, 100px);
  gap: 5px;
}

.cell {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 72px;
  font-weight: bold;
  background-color: #fff;
  border: 2px solid #000;
  cursor: pointer;
  transition: background-color 0.2s;
}

.cell:hover {
  background-color: #eee;
}

.game-status {
  font-size: 35px;
  margin-top: 10px;
  text-align: center;
  min-width: 320px;
  width: 100%;
}

body {
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f0f0f0;
  padding: 50px;
  text-align: center;
}

h1 {
  font-size: 32px;
  margin-bottom: 20px;
}

.game-board {
  display: inline-grid;
  grid-template-columns: repeat(3, 100px);
  grid-template-rows: repeat(3, 100px);
  gap: 5px;
}

.cell {
  width: 100px;
  height: 100px;
  background-color: #fff;
  border: 1px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  font-weight: bold;
  cursor: pointer;
  font-size:77px !important;
}

.game-status {
  margin-top: 20px;
  font-size: 30px;
  width: 100%;
  font-weight: bold;
}

button {
  padding: 10px 20px;
  font-size: 18px;
  margin-top: 20px;
  cursor: pointer;
}
